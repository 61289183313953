import {Injectable} from '@angular/core';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user';
import { environment } from '../../environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userObservable: Observable<SocialUser>;
  socialUser :SocialUser;
  timeout;
  timeoutPromise;
  accessToken = '';
  redirectUrl: string;

  constructor(private socialAuthService: SocialAuthService, private router: Router, private http: HttpClient, private snackBar: MatSnackBar) {
    this.userObservable = this.socialAuthService.authState;

    socialAuthService.authState.subscribe((socialUser) => {
      this.socialUser = socialUser;

      if (socialUser == null) {
        this.redirectUrl = window.location.pathname
        this.router.navigate(['/sign-in']);
      }
    });
  }

  isLoggedIn = (): Promise<boolean> => {
    return new Promise((resolve) => {
        this.getUser().then(u => resolve(u != null));
    });
  }

  getUser(): Promise<SocialUser> {
    return new Promise((resolve) => {
      if(!this.socialUser){
        this.timeout = setTimeout(() => {
          this.timeoutPromise = resolve
          resolve(this.socialUser);
        }, 1000)
      }else{
        resolve(this.socialUser)
      }
    });
  }

  getUserRole(): Promise<User> {
    return this.getUser().then(user => {
      const headers = new HttpHeaders({'Authorization': `Bearer ${user.idToken}`});
      return this.http.get(environment.baseUrl + `/api/email-roles`, {headers: headers})
        .toPromise()
        .catch(data => this.snackBar.open(data.message, "close", {duration: 5000}))
        .then(data => <User>data)
    });
  }

  get currentUser(): User {
    return <User>(JSON.parse(localStorage.getItem('user')));
  }
}
