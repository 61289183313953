import { Component, OnInit } from '@angular/core';
import {UserService} from "../services/user.service";
import {SocialUser} from "@abacritt/angularx-social-login";

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.sass']
})
export class LinksComponent implements OnInit {
  public user: SocialUser;
  constructor(private userService: UserService) { }

  ngOnInit() {
    this.userService.userObservable.subscribe(u => this.user = u);
  }

}
