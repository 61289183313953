export class MccCode {
    mcc: string;
    text: string;
}

export const MccCodes :MccCode[] = [
    {
      "mcc": "0742",
      "text": "Veterinary Services"
    },
    {
      "mcc": "0763",
      "text": "Agricultural Co-operatives"
    },
    {
      "mcc": "0780",
      "text": "Horticultural Services, Landscaping Services"
    },
    {
      "mcc": "1520",
      "text": "General Contractors-Residential and Commercial"
    },
    {
      "mcc": "1711",
      "text": "Air Conditioning Contractors – Sales and Installation, Heating Contractors – Sales, Service, Installation"
    },
    {
      "mcc": "1731",
      "text": "Electrical Contractors"
    },
    {
      "mcc": "1740",
      "text": "Insulation – Contractors, Masonry, Stonework Contractors, Plastering Contractors, Stonework and Masonry Contractors, Tile Settings Contractors"
    },
    {
      "mcc": "1750",
      "text": "Carpentry Contractors"
    },
    {
      "mcc": "1761",
      "text": "Roofing – Contractors, Sheet Metal Work – Contractors, Siding – Contractors"
    },
    {
      "mcc": "1771",
      "text": "Contractors – Concrete Work"
    },
    {
      "mcc": "1799",
      "text": "Contractors – Special Trade, Not Elsewhere Classified"
    },
    {
      "mcc": "2741",
      "text": "Miscellaneous Publishing and Printing"
    },
    {
      "mcc": "2791",
      "text": "Typesetting, Plate Making, & Related Services"
    },
    {
      "mcc": "2842",
      "text": "Specialty Cleaning, Polishing, and Sanitation Preparations"
    },
    {
      "mcc": "3000",
      "text": "UNITED AIRLINES"
    },
    {
      "mcc": "3001",
      "text": "AMERICAN AIRLINES"
    },
    {
      "mcc": "3002",
      "text": "PAN AMERICAN"
    },
    {
      "mcc": "3003",
      "text": "Airlines"
    },
    {
      "mcc": "3004",
      "text": "TRANS WORLD AIRLINES"
    },
    {
      "mcc": "3005",
      "text": "BRITISH AIRWAYS"
    },
    {
      "mcc": "3006",
      "text": "JAPAN AIRLINES"
    },
    {
      "mcc": "3007",
      "text": "AIR FRANCE"
    },
    {
      "mcc": "3008",
      "text": "LUFTHANSA"
    },
    {
      "mcc": "3009",
      "text": "AIR CANADA"
    },
    {
      "mcc": "3010",
      "text": "KLM (ROYAL DUTCH AIRLINES)"
    },
    {
      "mcc": "3011",
      "text": "AEORFLOT"
    },
    {
      "mcc": "3012",
      "text": "QANTAS"
    },
    {
      "mcc": "3013",
      "text": "ALITALIA"
    },
    {
      "mcc": "3014",
      "text": "SAUDIA ARABIAN AIRLINES"
    },
    {
      "mcc": "3015",
      "text": "SWISSAIR"
    },
    {
      "mcc": "3016",
      "text": "SAS"
    },
    {
      "mcc": "3017",
      "text": "SOUTH AFRICAN AIRWAYS"
    },
    {
      "mcc": "3018",
      "text": "VARIG (BRAZIL)"
    },
    {
      "mcc": "3019",
      "text": "Airlines"
    },
    {
      "mcc": "3020",
      "text": "AIR-INDIA"
    },
    {
      "mcc": "3021",
      "text": "AIR ALGERIE"
    },
    {
      "mcc": "3022",
      "text": "PHILIPPINE AIRLINES"
    },
    {
      "mcc": "3023",
      "text": "MEXICANA"
    },
    {
      "mcc": "3024",
      "text": "PAKISTAN INTERNATIONAL"
    },
    {
      "mcc": "3025",
      "text": "AIR NEW ZEALAND"
    },
    {
      "mcc": "3026",
      "text": "Airlines"
    },
    {
      "mcc": "3027",
      "text": "UTA/INTERAIR"
    },
    {
      "mcc": "3028",
      "text": "AIR MALTA"
    },
    {
      "mcc": "3029",
      "text": "SABENA"
    },
    {
      "mcc": "3030",
      "text": "AEROLINEAS ARGENTINAS"
    },
    {
      "mcc": "3031",
      "text": "OLYMPIC AIRWAYS"
    },
    {
      "mcc": "3032",
      "text": "EL AL"
    },
    {
      "mcc": "3033",
      "text": "ANSETT AIRLINES"
    },
    {
      "mcc": "3034",
      "text": "AUSTRAINLIAN AIRLINES"
    },
    {
      "mcc": "3035",
      "text": "TAP (PORTUGAL)"
    },
    {
      "mcc": "3036",
      "text": "VASP (BRAZIL)"
    },
    {
      "mcc": "3037",
      "text": "EGYPTAIR"
    },
    {
      "mcc": "3038",
      "text": "KUWAIT AIRLINES"
    },
    {
      "mcc": "3039",
      "text": "AVIANCA"
    },
    {
      "mcc": "3040",
      "text": "GULF AIR (BAHRAIN)"
    },
    {
      "mcc": "3041",
      "text": "BALKAN-BULGARIAN AIRLINES"
    },
    {
      "mcc": "3042",
      "text": "FINNAIR"
    },
    {
      "mcc": "3043",
      "text": "AER LINGUS"
    },
    {
      "mcc": "3044",
      "text": "AIR LANKA"
    },
    {
      "mcc": "3045",
      "text": "NIGERIA AIRWAYS"
    },
    {
      "mcc": "3046",
      "text": "CRUZEIRO DO SUL (BRAZIJ)"
    },
    {
      "mcc": "3047",
      "text": "THY (TURKEY)"
    },
    {
      "mcc": "3048",
      "text": "ROYAL AIR MAROC"
    },
    {
      "mcc": "3049",
      "text": "TUNIS AIR"
    },
    {
      "mcc": "3050",
      "text": "ICELANDAIR"
    },
    {
      "mcc": "3051",
      "text": "AUSTRIAN AIRLINES"
    },
    {
      "mcc": "3052",
      "text": "LANCHILE"
    },
    {
      "mcc": "3053",
      "text": "AVIACO (SPAIN)"
    },
    {
      "mcc": "3054",
      "text": "LADECO (CHILE)"
    },
    {
      "mcc": "3055",
      "text": "LAB (BOLIVIA)"
    },
    {
      "mcc": "3056",
      "text": "QUEBECAIRE"
    },
    {
      "mcc": "3057",
      "text": "EASTWEST AIRLINES (AUSTRALIA)"
    },
    {
      "mcc": "3058",
      "text": "DELTA"
    },
    {
      "mcc": "3059",
      "text": "Airlines"
    },
    {
      "mcc": "3060",
      "text": "NORTHWEST"
    },
    {
      "mcc": "3061",
      "text": "CONTINENTAL"
    },
    {
      "mcc": "3062",
      "text": "WESTERN"
    },
    {
      "mcc": "3063",
      "text": "US AIR"
    },
    {
      "mcc": "3064",
      "text": "Airlines"
    },
    {
      "mcc": "3065",
      "text": "AIRINTER"
    },
    {
      "mcc": "3066",
      "text": "SOUTHWEST"
    },
    {
      "mcc": "3067",
      "text": "Airlines"
    },
    {
      "mcc": "3068",
      "text": "Airlines"
    },
    {
      "mcc": "3069",
      "text": "SUN COUNTRY AIRLINES"
    },
    {
      "mcc": "3070",
      "text": "Airlines"
    },
    {
      "mcc": "3071",
      "text": "AIR BRITISH COLUBIA"
    },
    {
      "mcc": "3072",
      "text": "Airlines"
    },
    {
      "mcc": "3073",
      "text": "Airlines"
    },
    {
      "mcc": "3074",
      "text": "Airlines"
    },
    {
      "mcc": "3075",
      "text": "SINGAPORE AIRLINES"
    },
    {
      "mcc": "3076",
      "text": "AEROMEXICO"
    },
    {
      "mcc": "3077",
      "text": "THAI AIRWAYS"
    },
    {
      "mcc": "3078",
      "text": "CHINA AIRLINES"
    },
    {
      "mcc": "3079",
      "text": "Airlines"
    },
    {
      "mcc": "3080",
      "text": "Airlines"
    },
    {
      "mcc": "3081",
      "text": "NORDAIR"
    },
    {
      "mcc": "3082",
      "text": "KOREAN AIRLINES"
    },
    {
      "mcc": "3083",
      "text": "AIR AFRIGUE"
    },
    {
      "mcc": "3084",
      "text": "EVA AIRLINES"
    },
    {
      "mcc": "3085",
      "text": "MIDWEST EXPRESS AIRLINES, INC."
    },
    {
      "mcc": "3086",
      "text": "Airlines"
    },
    {
      "mcc": "3087",
      "text": "METRO AIRLINES"
    },
    {
      "mcc": "3088",
      "text": "CROATIA AIRLINES"
    },
    {
      "mcc": "3089",
      "text": "TRANSAERO"
    },
    {
      "mcc": "3090",
      "text": "Airlines"
    },
    {
      "mcc": "3091",
      "text": "Airlines"
    },
    {
      "mcc": "3092",
      "text": "Airlines"
    },
    {
      "mcc": "3093",
      "text": "Airlines"
    },
    {
      "mcc": "3094",
      "text": "ZAMBIA AIRWAYS"
    },
    {
      "mcc": "3095",
      "text": "Airlines"
    },
    {
      "mcc": "3096",
      "text": "AIR ZIMBABWE"
    },
    {
      "mcc": "3097",
      "text": "Airlines"
    },
    {
      "mcc": "3098",
      "text": "Airlines"
    },
    {
      "mcc": "3099",
      "text": "CATHAY PACIFIC"
    },
    {
      "mcc": "3100",
      "text": "MALAYSIAN AIRLINE SYSTEM"
    },
    {
      "mcc": "3101",
      "text": "Airlines"
    },
    {
      "mcc": "3102",
      "text": "IBERIA"
    },
    {
      "mcc": "3103",
      "text": "GARUDA (INDONESIA)"
    },
    {
      "mcc": "3104",
      "text": "Airlines"
    },
    {
      "mcc": "3105",
      "text": "Airlines"
    },
    {
      "mcc": "3106",
      "text": "BRAATHENS S.A.F.E. (NORWAY)"
    },
    {
      "mcc": "3107",
      "text": "Airlines"
    },
    {
      "mcc": "3108",
      "text": "Airlines"
    },
    {
      "mcc": "3109",
      "text": "Airlines"
    },
    {
      "mcc": "3110",
      "text": "WINGS AIRWAYS"
    },
    {
      "mcc": "3111",
      "text": "BRITISH MIDLAND"
    },
    {
      "mcc": "3112",
      "text": "WINDWARD ISLAND"
    },
    {
      "mcc": "3113",
      "text": "Airlines"
    },
    {
      "mcc": "3114",
      "text": "Airlines"
    },
    {
      "mcc": "3115",
      "text": "Airlines"
    },
    {
      "mcc": "3116",
      "text": "Airlines"
    },
    {
      "mcc": "3117",
      "text": "VIASA"
    },
    {
      "mcc": "3118",
      "text": "VALLEY AIRLINES"
    },
    {
      "mcc": "3119",
      "text": "Airlines"
    },
    {
      "mcc": "3120",
      "text": "Airlines"
    },
    {
      "mcc": "3121",
      "text": "Airlines"
    },
    {
      "mcc": "3122",
      "text": "Airlines"
    },
    {
      "mcc": "3123",
      "text": "Airlines"
    },
    {
      "mcc": "3124",
      "text": "Airlines"
    },
    {
      "mcc": "3125",
      "text": "TAN"
    },
    {
      "mcc": "3126",
      "text": "TALAIR"
    },
    {
      "mcc": "3127",
      "text": "TACA INTERNATIONAL"
    },
    {
      "mcc": "3128",
      "text": "Airlines"
    },
    {
      "mcc": "3129",
      "text": "SURINAM AIRWAYS"
    },
    {
      "mcc": "3130",
      "text": "SUN WORLD INTERNATIONAL"
    },
    {
      "mcc": "3131",
      "text": "Airlines"
    },
    {
      "mcc": "3132",
      "text": "Airlines"
    },
    {
      "mcc": "3133",
      "text": "SUNBELT AIRLINES"
    },
    {
      "mcc": "3134",
      "text": "Airlines"
    },
    {
      "mcc": "3135",
      "text": "SUDAN AIRWAYS"
    },
    {
      "mcc": "3136",
      "text": "Airlines"
    },
    {
      "mcc": "3137",
      "text": "SINGLETON"
    },
    {
      "mcc": "3138",
      "text": "SIMMONS AIRLINES"
    },
    {
      "mcc": "3139",
      "text": "Airlines"
    },
    {
      "mcc": "3140",
      "text": "Airlines"
    },
    {
      "mcc": "3141",
      "text": "Airlines"
    },
    {
      "mcc": "3142",
      "text": "Airlines"
    },
    {
      "mcc": "3143",
      "text": "SCENIC AIRLINES"
    },
    {
      "mcc": "3144",
      "text": "VIRGIN ATLANTIC"
    },
    {
      "mcc": "3145",
      "text": "SAN JUAN AIRLINES"
    },
    {
      "mcc": "3146",
      "text": "LUXAIR"
    },
    {
      "mcc": "3147",
      "text": "Airlines"
    },
    {
      "mcc": "3148",
      "text": "Airlines"
    },
    {
      "mcc": "3149",
      "text": "Airlines"
    },
    {
      "mcc": "3150",
      "text": "Airlines"
    },
    {
      "mcc": "3151",
      "text": "AIR ZAIRE"
    },
    {
      "mcc": "3152",
      "text": "Airlines"
    },
    {
      "mcc": "3153",
      "text": "Airlines"
    },
    {
      "mcc": "3154",
      "text": "PRINCEVILLE"
    },
    {
      "mcc": "3155",
      "text": "Airlines"
    },
    {
      "mcc": "3156",
      "text": "Airlines"
    },
    {
      "mcc": "3157",
      "text": "Airlines"
    },
    {
      "mcc": "3158",
      "text": "Airlines"
    },
    {
      "mcc": "3159",
      "text": "PBA"
    },
    {
      "mcc": "3160",
      "text": "Airlines"
    },
    {
      "mcc": "3161",
      "text": "ALL NIPPON AIRWAYS"
    },
    {
      "mcc": "3162",
      "text": "Airlines"
    },
    {
      "mcc": "3163",
      "text": "Airlines"
    },
    {
      "mcc": "3164",
      "text": "NORONTAIR"
    },
    {
      "mcc": "3165",
      "text": "NEW YORK HELICOPTER"
    },
    {
      "mcc": "3166",
      "text": "Airlines"
    },
    {
      "mcc": "3167",
      "text": "Airlines"
    },
    {
      "mcc": "3168",
      "text": "Airlines"
    },
    {
      "mcc": "3169",
      "text": "Airlines"
    },
    {
      "mcc": "3170",
      "text": "NOUNT COOK"
    },
    {
      "mcc": "3171",
      "text": "CANADIAN AIRLINES INTERNATIONAL"
    },
    {
      "mcc": "3172",
      "text": "NATIONAIR"
    },
    {
      "mcc": "3173",
      "text": "Airlines"
    },
    {
      "mcc": "3174",
      "text": "Airlines"
    },
    {
      "mcc": "3175",
      "text": "Airlines"
    },
    {
      "mcc": "3176",
      "text": "METROFLIGHT AIRLINES"
    },
    {
      "mcc": "3177",
      "text": "Airlines"
    },
    {
      "mcc": "3178",
      "text": "MESA AIR"
    },
    {
      "mcc": "3179",
      "text": "Airlines"
    },
    {
      "mcc": "3180",
      "text": "Airlines"
    },
    {
      "mcc": "3181",
      "text": "MALEV"
    },
    {
      "mcc": "3182",
      "text": "LOT (POLAND)"
    },
    {
      "mcc": "3183",
      "text": "Airlines"
    },
    {
      "mcc": "3184",
      "text": "LIAT"
    },
    {
      "mcc": "3185",
      "text": "LAV (VENEZUELA)"
    },
    {
      "mcc": "3186",
      "text": "LAP (PARAGUAY)"
    },
    {
      "mcc": "3187",
      "text": "LACSA (COSTA RICA)"
    },
    {
      "mcc": "3188",
      "text": "Airlines"
    },
    {
      "mcc": "3189",
      "text": "Airlines"
    },
    {
      "mcc": "3190",
      "text": "JUGOSLAV AIR"
    },
    {
      "mcc": "3191",
      "text": "ISLAND AIRLINES"
    },
    {
      "mcc": "3192",
      "text": "IRAN AIR"
    },
    {
      "mcc": "3193",
      "text": "INDIAN AIRLINES"
    },
    {
      "mcc": "3194",
      "text": "Airlines"
    },
    {
      "mcc": "3195",
      "text": "Airlines"
    },
    {
      "mcc": "3196",
      "text": "HAWAIIAN AIR"
    },
    {
      "mcc": "3197",
      "text": "HAVASU AIRLINES"
    },
    {
      "mcc": "3198",
      "text": "Airlines"
    },
    {
      "mcc": "3199",
      "text": "Airlines"
    },
    {
      "mcc": "3200",
      "text": "FUYANA AIRWAYS"
    },
    {
      "mcc": "3201",
      "text": "Airlines"
    },
    {
      "mcc": "3202",
      "text": "Airlines"
    },
    {
      "mcc": "3203",
      "text": "GOLDEN PACIFIC AIR"
    },
    {
      "mcc": "3204",
      "text": "FREEDOM AIR"
    },
    {
      "mcc": "3205",
      "text": "Airlines"
    },
    {
      "mcc": "3206",
      "text": "Airlines"
    },
    {
      "mcc": "3207",
      "text": "Airlines"
    },
    {
      "mcc": "3208",
      "text": "Airlines"
    },
    {
      "mcc": "3209",
      "text": "Airlines"
    },
    {
      "mcc": "3210",
      "text": "Airlines"
    },
    {
      "mcc": "3211",
      "text": "Airlines"
    },
    {
      "mcc": "3212",
      "text": "DOMINICANA"
    },
    {
      "mcc": "3213",
      "text": "Airlines"
    },
    {
      "mcc": "3214",
      "text": "Airlines"
    },
    {
      "mcc": "3215",
      "text": "DAN AIR SERVICES"
    },
    {
      "mcc": "3216",
      "text": "CUMBERLAND AIRLINES"
    },
    {
      "mcc": "3217",
      "text": "CSA"
    },
    {
      "mcc": "3218",
      "text": "CROWN AIR"
    },
    {
      "mcc": "3219",
      "text": "COPA"
    },
    {
      "mcc": "3220",
      "text": "COMPANIA FAUCETT"
    },
    {
      "mcc": "3221",
      "text": "TRANSPORTES AEROS MILITARES ECCUATORANOS"
    },
    {
      "mcc": "3222",
      "text": "COMMAND AIRWAYS"
    },
    {
      "mcc": "3223",
      "text": "COMAIR"
    },
    {
      "mcc": "3224",
      "text": "Airlines"
    },
    {
      "mcc": "3225",
      "text": "Airlines"
    },
    {
      "mcc": "3226",
      "text": "Airlines"
    },
    {
      "mcc": "3227",
      "text": "Airlines"
    },
    {
      "mcc": "3228",
      "text": "CAYMAN AIRWAYS"
    },
    {
      "mcc": "3229",
      "text": "SAETA SOCIAEDAD ECUATORIANOS DE TRANSPORTES AEREOS"
    },
    {
      "mcc": "3230",
      "text": "Airlines"
    },
    {
      "mcc": "3231",
      "text": "SASHA SERVICIO AERO DE HONDURAS"
    },
    {
      "mcc": "3232",
      "text": "Airlines"
    },
    {
      "mcc": "3233",
      "text": "CAPITOL AIR"
    },
    {
      "mcc": "3234",
      "text": "BWIA"
    },
    {
      "mcc": "3235",
      "text": "BROKWAY AIR"
    },
    {
      "mcc": "3236",
      "text": "Airlines"
    },
    {
      "mcc": "3237",
      "text": "Airlines"
    },
    {
      "mcc": "3238",
      "text": "BEMIDJI AIRLINES"
    },
    {
      "mcc": "3239",
      "text": "BAR HARBOR AIRLINES"
    },
    {
      "mcc": "3240",
      "text": "BAHAMASAIR"
    },
    {
      "mcc": "3241",
      "text": "AVIATECA (GUATEMALA)"
    },
    {
      "mcc": "3242",
      "text": "AVENSA"
    },
    {
      "mcc": "3243",
      "text": "AUSTRIAN AIR SERVICE"
    },
    {
      "mcc": "3244",
      "text": "Airlines"
    },
    {
      "mcc": "3245",
      "text": "Airlines"
    },
    {
      "mcc": "3246",
      "text": "Airlines"
    },
    {
      "mcc": "3247",
      "text": "Airlines"
    },
    {
      "mcc": "3248",
      "text": "Airlines"
    },
    {
      "mcc": "3249",
      "text": "Airlines"
    },
    {
      "mcc": "3250",
      "text": "Airlines"
    },
    {
      "mcc": "3251",
      "text": "ALOHA AIRLINES"
    },
    {
      "mcc": "3252",
      "text": "ALM"
    },
    {
      "mcc": "3253",
      "text": "AMERICA WEST"
    },
    {
      "mcc": "3254",
      "text": "TRUMP AIRLINE"
    },
    {
      "mcc": "3255",
      "text": "Airlines"
    },
    {
      "mcc": "3256",
      "text": "ALASKA AIRLINES"
    },
    {
      "mcc": "3257",
      "text": "Airlines"
    },
    {
      "mcc": "3258",
      "text": "Airlines"
    },
    {
      "mcc": "3259",
      "text": "AMERICAN TRANS AIR"
    },
    {
      "mcc": "3260",
      "text": "Airlines"
    },
    {
      "mcc": "3261",
      "text": "AIR CHINA"
    },
    {
      "mcc": "3262",
      "text": "RENO AIR, INC."
    },
    {
      "mcc": "3263",
      "text": "Airlines"
    },
    {
      "mcc": "3264",
      "text": "Airlines"
    },
    {
      "mcc": "3265",
      "text": "Airlines"
    },
    {
      "mcc": "3266",
      "text": "AIR SEYCHELLES"
    },
    {
      "mcc": "3267",
      "text": "AIR PANAMA"
    },
    {
      "mcc": "3268",
      "text": "Airlines"
    },
    {
      "mcc": "3269",
      "text": "Airlines"
    },
    {
      "mcc": "3270",
      "text": "Airlines"
    },
    {
      "mcc": "3271",
      "text": "Airlines"
    },
    {
      "mcc": "3272",
      "text": "Airlines"
    },
    {
      "mcc": "3273",
      "text": "Airlines"
    },
    {
      "mcc": "3274",
      "text": "Airlines"
    },
    {
      "mcc": "3275",
      "text": "Airlines"
    },
    {
      "mcc": "3276",
      "text": "Airlines"
    },
    {
      "mcc": "3277",
      "text": "Airlines"
    },
    {
      "mcc": "3278",
      "text": "Airlines"
    },
    {
      "mcc": "3279",
      "text": "Airlines"
    },
    {
      "mcc": "3280",
      "text": "AIR JAMAICA"
    },
    {
      "mcc": "3281",
      "text": "Airlines"
    },
    {
      "mcc": "3282",
      "text": "AIR DJIBOUTI"
    },
    {
      "mcc": "3283",
      "text": "Airlines"
    },
    {
      "mcc": "3284",
      "text": "AERO VIRGIN ISLANDS"
    },
    {
      "mcc": "3285",
      "text": "AERO PERU"
    },
    {
      "mcc": "3286",
      "text": "AEROLINEAS NICARAGUENSIS"
    },
    {
      "mcc": "3287",
      "text": "AERO COACH AVAIATION"
    },
    {
      "mcc": "3288",
      "text": "Airlines"
    },
    {
      "mcc": "3289",
      "text": "Airlines"
    },
    {
      "mcc": "3290",
      "text": "Airlines"
    },
    {
      "mcc": "3291",
      "text": "ARIANA AFGHAN"
    },
    {
      "mcc": "3292",
      "text": "CYPRUS AIRWAYS"
    },
    {
      "mcc": "3293",
      "text": "ECUATORIANA"
    },
    {
      "mcc": "3294",
      "text": "ETHIOPIAN AIRLINES"
    },
    {
      "mcc": "3295",
      "text": "KENYA AIRLINES"
    },
    {
      "mcc": "3296",
      "text": "Airlines"
    },
    {
      "mcc": "3297",
      "text": "Airlines"
    },
    {
      "mcc": "3298",
      "text": "AIR MAURITIUS"
    },
    {
      "mcc": "3299",
      "text": "WIDERO’S FLYVESELSKAP"
    },
    {
      "mcc": "3351",
      "text": "AFFILIATED AUTO RENTAL"
    },
    {
      "mcc": "3352",
      "text": "AMERICAN INTL RENT-A-CAR"
    },
    {
      "mcc": "3353",
      "text": "BROOKS RENT-A-CAR"
    },
    {
      "mcc": "3354",
      "text": "ACTION AUTO RENTAL"
    },
    {
      "mcc": "3355",
      "text": "Car Rental"
    },
    {
      "mcc": "3356",
      "text": "Car Rental"
    },
    {
      "mcc": "3357",
      "text": "HERTZ RENT-A-CAR"
    },
    {
      "mcc": "3358",
      "text": "Car Rental"
    },
    {
      "mcc": "3359",
      "text": "PAYLESS CAR RENTAL"
    },
    {
      "mcc": "3360",
      "text": "SNAPPY CAR RENTAL"
    },
    {
      "mcc": "3361",
      "text": "AIRWAYS RENT-A-CAR"
    },
    {
      "mcc": "3362",
      "text": "ALTRA AUTO RENTAL"
    },
    {
      "mcc": "3363",
      "text": "Car Rental"
    },
    {
      "mcc": "3364",
      "text": "AGENCY RENT-A-CAR"
    },
    {
      "mcc": "3365",
      "text": "Car Rental"
    },
    {
      "mcc": "3366",
      "text": "BUDGET RENT-A-CAR"
    },
    {
      "mcc": "3367",
      "text": "Car Rental"
    },
    {
      "mcc": "3368",
      "text": "HOLIDAY RENT-A-WRECK"
    },
    {
      "mcc": "3369",
      "text": "Car Rental"
    },
    {
      "mcc": "3370",
      "text": "RENT-A-WRECK"
    },
    {
      "mcc": "3371",
      "text": "Car Rental"
    },
    {
      "mcc": "3372",
      "text": "Car Rental"
    },
    {
      "mcc": "3373",
      "text": "Car Rental"
    },
    {
      "mcc": "3374",
      "text": "Car Rental"
    },
    {
      "mcc": "3375",
      "text": "Car Rental"
    },
    {
      "mcc": "3376",
      "text": "AJAX RENT-A-CAR"
    },
    {
      "mcc": "3377",
      "text": "Car Rental"
    },
    {
      "mcc": "3378",
      "text": "Car Rental"
    },
    {
      "mcc": "3379",
      "text": "Car Rental"
    },
    {
      "mcc": "3380",
      "text": "Car Rental"
    },
    {
      "mcc": "3381",
      "text": "EUROP CAR"
    },
    {
      "mcc": "3382",
      "text": "Car Rental"
    },
    {
      "mcc": "3383",
      "text": "Car Rental"
    },
    {
      "mcc": "3384",
      "text": "Car Rental"
    },
    {
      "mcc": "3385",
      "text": "TROPICAL RENT-A-CAR"
    },
    {
      "mcc": "3386",
      "text": "SHOWCASE RENTAL CARS"
    },
    {
      "mcc": "3387",
      "text": "ALAMO RENT-A-CAR"
    },
    {
      "mcc": "3388",
      "text": "Car Rental"
    },
    {
      "mcc": "3389",
      "text": "AVIS RENT-A-CAR"
    },
    {
      "mcc": "3390",
      "text": "DOLLAR RENT-A-CAR"
    },
    {
      "mcc": "3391",
      "text": "EUROPE BY CAR"
    },
    {
      "mcc": "3392",
      "text": "Car Rental"
    },
    {
      "mcc": "3393",
      "text": "NATIONAL CAR RENTAL"
    },
    {
      "mcc": "3394",
      "text": "KEMWELL GROUP RENT-A-CAR"
    },
    {
      "mcc": "3395",
      "text": "THRIFTY RENT-A-CAR"
    },
    {
      "mcc": "3396",
      "text": "TILDEN TENT-A-CAR"
    },
    {
      "mcc": "3397",
      "text": "Car Rental"
    },
    {
      "mcc": "3398",
      "text": "ECONO-CAR RENT-A-CAR"
    },
    {
      "mcc": "3399",
      "text": "Car Rental"
    },
    {
      "mcc": "3400",
      "text": "AUTO HOST COST CAR RENTALS"
    },
    {
      "mcc": "3401",
      "text": "Car Rental"
    },
    {
      "mcc": "3402",
      "text": "Car Rental"
    },
    {
      "mcc": "3403",
      "text": "Car Rental"
    },
    {
      "mcc": "3404",
      "text": "Car Rental"
    },
    {
      "mcc": "3405",
      "text": "ENTERPRISE RENT-A-CAR"
    },
    {
      "mcc": "3406",
      "text": "Car Rental"
    },
    {
      "mcc": "3407",
      "text": "Car Rental"
    },
    {
      "mcc": "3408",
      "text": "Car Rental"
    },
    {
      "mcc": "3409",
      "text": "GENERAL RENT-A-CAR"
    },
    {
      "mcc": "3410",
      "text": "Car Rental"
    },
    {
      "mcc": "3411",
      "text": "Car Rental"
    },
    {
      "mcc": "3412",
      "text": "A-1 RENT-A-CAR"
    },
    {
      "mcc": "3413",
      "text": "Car Rental"
    },
    {
      "mcc": "3414",
      "text": "GODFREY NATL RENT-A-CAR"
    },
    {
      "mcc": "3415",
      "text": "Car Rental"
    },
    {
      "mcc": "3416",
      "text": "Car Rental"
    },
    {
      "mcc": "3417",
      "text": "Car Rental"
    },
    {
      "mcc": "3418",
      "text": "Car Rental"
    },
    {
      "mcc": "3419",
      "text": "ALPHA RENT-A-CAR"
    },
    {
      "mcc": "3420",
      "text": "ANSA INTL RENT-A-CAR"
    },
    {
      "mcc": "3421",
      "text": "ALLSTAE RENT-A-CAR"
    },
    {
      "mcc": "3422",
      "text": "Car Rental"
    },
    {
      "mcc": "3423",
      "text": "AVCAR RENT-A-CAR"
    },
    {
      "mcc": "3424",
      "text": "Car Rental"
    },
    {
      "mcc": "3425",
      "text": "AUTOMATE RENT-A-CAR"
    },
    {
      "mcc": "3426",
      "text": "Car Rental"
    },
    {
      "mcc": "3427",
      "text": "AVON RENT-A-CAR"
    },
    {
      "mcc": "3428",
      "text": "CAREY RENT-A-CAR"
    },
    {
      "mcc": "3429",
      "text": "INSURANCE RENT-A-CAR"
    },
    {
      "mcc": "3430",
      "text": "MAJOR RENT-A-CAR"
    },
    {
      "mcc": "3431",
      "text": "REPLACEMENT RENT-A-CAR"
    },
    {
      "mcc": "3432",
      "text": "RESERVE RENT-A-CAR"
    },
    {
      "mcc": "3433",
      "text": "UGLY DUCKLING RENT-A-CAR"
    },
    {
      "mcc": "3434",
      "text": "USA RENT-A-CAR"
    },
    {
      "mcc": "3435",
      "text": "VALUE RENT-A-CAR"
    },
    {
      "mcc": "3436",
      "text": "AUTOHANSA RENT-A-CAR"
    },
    {
      "mcc": "3437",
      "text": "CITE RENT-A-CAR"
    },
    {
      "mcc": "3438",
      "text": "INTERENT RENT-A-CAR"
    },
    {
      "mcc": "3439",
      "text": "MILLEVILLE RENT-A-CAR"
    },
    {
      "mcc": "3440",
      "text": "VIA ROUTE RENT-A-CAR"
    },
    {
      "mcc": "3441",
      "text": "Car Rental"
    },
    {
      "mcc": "3501",
      "text": "HOLIDAY INNS, HOLIDAY INN EXPRESS"
    },
    {
      "mcc": "3502",
      "text": "BEST WESTERN HOTELS"
    },
    {
      "mcc": "3503",
      "text": "SHERATON HOTELS"
    },
    {
      "mcc": "3504",
      "text": "HILTON HOTELS"
    },
    {
      "mcc": "3505",
      "text": "FORTE HOTELS"
    },
    {
      "mcc": "3506",
      "text": "GOLDEN TULIP HOTELS"
    },
    {
      "mcc": "3507",
      "text": "FRIENDSHIP INNS"
    },
    {
      "mcc": "3508",
      "text": "QUALITY INNS, QUALITY SUITES"
    },
    {
      "mcc": "3509",
      "text": "MARRIOTT HOTELS"
    },
    {
      "mcc": "3510",
      "text": "DAYS INN, DAYSTOP"
    },
    {
      "mcc": "3511",
      "text": "ARABELLA HOTELS"
    },
    {
      "mcc": "3512",
      "text": "INTER-CONTINENTAL HOTELS"
    },
    {
      "mcc": "3513",
      "text": "WESTIN HOTELS"
    },
    {
      "mcc": "3514",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3515",
      "text": "RODEWAY INNS"
    },
    {
      "mcc": "3516",
      "text": "LA QUINTA MOTOR INNS"
    },
    {
      "mcc": "3517",
      "text": "AMERICANA HOTELS"
    },
    {
      "mcc": "3518",
      "text": "SOL HOTELS"
    },
    {
      "mcc": "3519",
      "text": "PULLMAN INTERNATIONAL HOTELS"
    },
    {
      "mcc": "3520",
      "text": "MERIDIEN HOTELS"
    },
    {
      "mcc": "3521",
      "text": "CREST HOTELS (see FORTE HOTELS)"
    },
    {
      "mcc": "3522",
      "text": "TOKYO HOTEL"
    },
    {
      "mcc": "3523",
      "text": "PENNSULA HOTEL"
    },
    {
      "mcc": "3524",
      "text": "WELCOMGROUP HOTELS"
    },
    {
      "mcc": "3525",
      "text": "DUNFEY HOTELS"
    },
    {
      "mcc": "3526",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3527",
      "text": "DOWNTOWNER-PASSPORT HOTEL"
    },
    {
      "mcc": "3528",
      "text": "RED LION HOTELS, RED LION INNS"
    },
    {
      "mcc": "3529",
      "text": "CP HOTELS"
    },
    {
      "mcc": "3530",
      "text": "RENAISSANCE HOTELS, STOUFFER HOTELS"
    },
    {
      "mcc": "3531",
      "text": "ASTIR HOTELS"
    },
    {
      "mcc": "3532",
      "text": "SUN ROUTE HOTELS"
    },
    {
      "mcc": "3533",
      "text": "HOTEL IBIS"
    },
    {
      "mcc": "3534",
      "text": "SOUTHERN PACIFIC HOTELS"
    },
    {
      "mcc": "3535",
      "text": "HILTON INTERNATIONAL"
    },
    {
      "mcc": "3536",
      "text": "AMFAC HOTELS"
    },
    {
      "mcc": "3537",
      "text": "ANA HOTEL"
    },
    {
      "mcc": "3538",
      "text": "CONCORDE HOTELS"
    },
    {
      "mcc": "3539",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3540",
      "text": "IBEROTEL HOTELS"
    },
    {
      "mcc": "3541",
      "text": "HOTEL OKURA"
    },
    {
      "mcc": "3542",
      "text": "ROYAL HOTELS"
    },
    {
      "mcc": "3543",
      "text": "FOUR SEASONS HOTELS"
    },
    {
      "mcc": "3544",
      "text": "CIGA HOTELS"
    },
    {
      "mcc": "3545",
      "text": "SHANGRI-LA INTERNATIONAL"
    },
    {
      "mcc": "3546",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3547",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3548",
      "text": "HOTELES MELIA"
    },
    {
      "mcc": "3549",
      "text": "AUBERGE DES GOVERNEURS"
    },
    {
      "mcc": "3550",
      "text": "REGAL 8 INNS"
    },
    {
      "mcc": "3551",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3552",
      "text": "COAST HOTELS"
    },
    {
      "mcc": "3553",
      "text": "PARK INNS INTERNATIONAL"
    },
    {
      "mcc": "3554",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3555",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3556",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3557",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3558",
      "text": "JOLLY HOTELS"
    },
    {
      "mcc": "3559",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3560",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3561",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3562",
      "text": "COMFORT INNS"
    },
    {
      "mcc": "3563",
      "text": "JOURNEY’S END MOTLS"
    },
    {
      "mcc": "3564",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3565",
      "text": "RELAX INNS"
    },
    {
      "mcc": "3566",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3567",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3568",
      "text": "LADBROKE HOTELS"
    },
    {
      "mcc": "3569",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3570",
      "text": "FORUM HOTELS"
    },
    {
      "mcc": "3571",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3572",
      "text": "MIYAKO HOTELS"
    },
    {
      "mcc": "3573",
      "text": "SANDMAN HOTELS"
    },
    {
      "mcc": "3574",
      "text": "VENTURE INNS"
    },
    {
      "mcc": "3575",
      "text": "VAGABOND HOTELS"
    },
    {
      "mcc": "3576",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3577",
      "text": "MANDARIN ORIENTAL HOTEL"
    },
    {
      "mcc": "3578",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3579",
      "text": "HOTEL MERCURE"
    },
    {
      "mcc": "3580",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3581",
      "text": "DELTA HOTEL"
    },
    {
      "mcc": "3582",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3583",
      "text": "SAS HOTELS"
    },
    {
      "mcc": "3584",
      "text": "PRINCESS HOTELS INTERNATIONAL"
    },
    {
      "mcc": "3585",
      "text": "HUNGAR HOTELS"
    },
    {
      "mcc": "3586",
      "text": "SOKOS HOTELS"
    },
    {
      "mcc": "3587",
      "text": "DORAL HOTELS"
    },
    {
      "mcc": "3588",
      "text": "HELMSLEY HOTELS"
    },
    {
      "mcc": "3589",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3590",
      "text": "FAIRMONT HOTELS"
    },
    {
      "mcc": "3591",
      "text": "SONESTA HOTELS"
    },
    {
      "mcc": "3592",
      "text": "OMNI HOTELS"
    },
    {
      "mcc": "3593",
      "text": "CUNARD HOTELS"
    },
    {
      "mcc": "3594",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3595",
      "text": "HOSPITALITY INTERNATIONAL"
    },
    {
      "mcc": "3596",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3597",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3598",
      "text": "REGENT INTERNATIONAL HOTELS"
    },
    {
      "mcc": "3599",
      "text": "PANNONIA HOTELS"
    },
    {
      "mcc": "3600",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3601",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3602",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3603",
      "text": "NOAH’S HOTELS"
    },
    {
      "mcc": "3604",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3605",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3606",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3607",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3608",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3609",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3610",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3611",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3612",
      "text": "MOVENPICK HOTELS"
    },
    {
      "mcc": "3613",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3614",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3615",
      "text": "TRAVELODGE"
    },
    {
      "mcc": "3616",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3617",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3618",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3619",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3620",
      "text": "TELFORD INTERNATIONAL"
    },
    {
      "mcc": "3621",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3622",
      "text": "MERLIN HOTELS"
    },
    {
      "mcc": "3623",
      "text": "DORINT HOTELS"
    },
    {
      "mcc": "3624",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3625",
      "text": "HOTLE UNIVERSALE"
    },
    {
      "mcc": "3626",
      "text": "PRINCE HOTELS"
    },
    {
      "mcc": "3627",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3628",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3629",
      "text": "DAN HOTELS"
    },
    {
      "mcc": "3630",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3631",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3632",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3633",
      "text": "RANK HOTELS"
    },
    {
      "mcc": "3634",
      "text": "SWISSOTEL"
    },
    {
      "mcc": "3635",
      "text": "RESO HOTELS"
    },
    {
      "mcc": "3636",
      "text": "SAROVA HOTELS"
    },
    {
      "mcc": "3637",
      "text": "RAMADA INNS, RAMADA LIMITED"
    },
    {
      "mcc": "3638",
      "text": "HO JO INN, HOWARD JOHNSON"
    },
    {
      "mcc": "3639",
      "text": "MOUNT CHARLOTTE THISTLE"
    },
    {
      "mcc": "3640",
      "text": "HYATT HOTEL"
    },
    {
      "mcc": "3641",
      "text": "SOFITEL HOTELS"
    },
    {
      "mcc": "3642",
      "text": "NOVOTEL HOTELS"
    },
    {
      "mcc": "3643",
      "text": "STEIGENBERGER HOTELS"
    },
    {
      "mcc": "3644",
      "text": "ECONO LODGES"
    },
    {
      "mcc": "3645",
      "text": "QUEENS MOAT HOUSES"
    },
    {
      "mcc": "3646",
      "text": "SWALLOW HOTELS"
    },
    {
      "mcc": "3647",
      "text": "HUSA HOTELS"
    },
    {
      "mcc": "3648",
      "text": "DE VERE HOTELS"
    },
    {
      "mcc": "3649",
      "text": "RADISSON HOTELS"
    },
    {
      "mcc": "3650",
      "text": "RED ROOK INNS"
    },
    {
      "mcc": "3651",
      "text": "IMPERIAL LONDON HOTEL"
    },
    {
      "mcc": "3652",
      "text": "EMBASSY HOTELS"
    },
    {
      "mcc": "3653",
      "text": "PENTA HOTELS"
    },
    {
      "mcc": "3654",
      "text": "LOEWS HOTELS"
    },
    {
      "mcc": "3655",
      "text": "SCANDIC HOTELS"
    },
    {
      "mcc": "3656",
      "text": "SARA HOTELS"
    },
    {
      "mcc": "3657",
      "text": "OBEROI HOTELS"
    },
    {
      "mcc": "3658",
      "text": "OTANI HOTELS"
    },
    {
      "mcc": "3659",
      "text": "TAJ HOTELS INTERNATIONAL"
    },
    {
      "mcc": "3660",
      "text": "KNIGHTS INNS"
    },
    {
      "mcc": "3661",
      "text": "METROPOLE HOTELS"
    },
    {
      "mcc": "3662",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3663",
      "text": "HOTELES EL PRESIDENTS"
    },
    {
      "mcc": "3664",
      "text": "FLAG INN"
    },
    {
      "mcc": "3665",
      "text": "HAMPTON INNS"
    },
    {
      "mcc": "3666",
      "text": "STAKIS HOTELS"
    },
    {
      "mcc": "3667",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3668",
      "text": "MARITIM HOTELS"
    },
    {
      "mcc": "3669",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3670",
      "text": "ARCARD HOTELS"
    },
    {
      "mcc": "3671",
      "text": "ARCTIA HOTELS"
    },
    {
      "mcc": "3672",
      "text": "CAMPANIEL HOTELS"
    },
    {
      "mcc": "3673",
      "text": "IBUSZ HOTELS"
    },
    {
      "mcc": "3674",
      "text": "RANTASIPI HOTELS"
    },
    {
      "mcc": "3675",
      "text": "INTERHOTEL CEDOK"
    },
    {
      "mcc": "3676",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3677",
      "text": "CLIMAT DE FRANCE HOTELS"
    },
    {
      "mcc": "3678",
      "text": "CUMULUS HOTELS"
    },
    {
      "mcc": "3679",
      "text": "DANUBIUS HOTEL"
    },
    {
      "mcc": "3680",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3681",
      "text": "ADAMS MARK HOTELS"
    },
    {
      "mcc": "3682",
      "text": "ALLSTAR INNS"
    },
    {
      "mcc": "3683",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3684",
      "text": "BUDGET HOST INNS"
    },
    {
      "mcc": "3685",
      "text": "BUDGETEL HOTELS"
    },
    {
      "mcc": "3686",
      "text": "SUISSE CHALETS"
    },
    {
      "mcc": "3687",
      "text": "CLARION HOTELS"
    },
    {
      "mcc": "3688",
      "text": "COMPRI HOTELS"
    },
    {
      "mcc": "3689",
      "text": "CONSORT HOTELS"
    },
    {
      "mcc": "3690",
      "text": "COURTYARD BY MARRIOTT"
    },
    {
      "mcc": "3691",
      "text": "DILLION INNS"
    },
    {
      "mcc": "3692",
      "text": "DOUBLETREE HOTELS"
    },
    {
      "mcc": "3693",
      "text": "DRURY INNS"
    },
    {
      "mcc": "3694",
      "text": "ECONOMY INNS OF AMERICA"
    },
    {
      "mcc": "3695",
      "text": "EMBASSY SUITES"
    },
    {
      "mcc": "3696",
      "text": "EXEL INNS"
    },
    {
      "mcc": "3697",
      "text": "FARFIELD HOTELS"
    },
    {
      "mcc": "3698",
      "text": "HARLEY HOTELS"
    },
    {
      "mcc": "3699",
      "text": "MIDWAY MOTOR LODGE"
    },
    {
      "mcc": "3700",
      "text": "MOTEL 6"
    },
    {
      "mcc": "3701",
      "text": "GUEST QUARTERS (Formally PICKETT SUITE HOTELS)"
    },
    {
      "mcc": "3702",
      "text": "THE REGISTRY HOTELS"
    },
    {
      "mcc": "3703",
      "text": "RESIDENCE INNS"
    },
    {
      "mcc": "3704",
      "text": "ROYCE HOTELS"
    },
    {
      "mcc": "3705",
      "text": "SANDMAN INNS"
    },
    {
      "mcc": "3706",
      "text": "SHILO INNS"
    },
    {
      "mcc": "3707",
      "text": "SHONEY’S INNS"
    },
    {
      "mcc": "3708",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3709",
      "text": "SUPER8 MOTELS"
    },
    {
      "mcc": "3710",
      "text": "THE RITZ CARLTON HOTELS"
    },
    {
      "mcc": "3711",
      "text": "FLAG INNS (AUSRALIA)"
    },
    {
      "mcc": "3712",
      "text": "GOLDEN CHAIN HOTEL"
    },
    {
      "mcc": "3713",
      "text": "QUALITY PACIFIC HOTEL"
    },
    {
      "mcc": "3714",
      "text": "FOUR SEASONS HOTEL (AUSTRALIA)"
    },
    {
      "mcc": "3715",
      "text": "FARIFIELD INN"
    },
    {
      "mcc": "3716",
      "text": "CARLTON HOTELS"
    },
    {
      "mcc": "3717",
      "text": "CITY LODGE HOTELS"
    },
    {
      "mcc": "3718",
      "text": "KAROS HOTELS"
    },
    {
      "mcc": "3719",
      "text": "PROTEA HOTELS"
    },
    {
      "mcc": "3720",
      "text": "SOUTHERN SUN HOTELS"
    },
    {
      "mcc": "3721",
      "text": "HILTON CONRAD"
    },
    {
      "mcc": "3722",
      "text": "WYNDHAM HOTEL AND RESORTS"
    },
    {
      "mcc": "3723",
      "text": "RICA HOTELS"
    },
    {
      "mcc": "3724",
      "text": "INER NOR HOTELS"
    },
    {
      "mcc": "3725",
      "text": "SEAINES PLANATION"
    },
    {
      "mcc": "3726",
      "text": "RIO SUITES"
    },
    {
      "mcc": "3727",
      "text": "BROADMOOR HOTEL"
    },
    {
      "mcc": "3728",
      "text": "BALLY’S HOTEL AND CASINO"
    },
    {
      "mcc": "3729",
      "text": "JOHN ASCUAGA’S NUGGET"
    },
    {
      "mcc": "3730",
      "text": "MGM GRAND HOTEL"
    },
    {
      "mcc": "3731",
      "text": "HARRAH’S HOTELS AND CASINOS"
    },
    {
      "mcc": "3732",
      "text": "OPRYLAND HOTEL"
    },
    {
      "mcc": "3733",
      "text": "BOCA RATON RESORT"
    },
    {
      "mcc": "3734",
      "text": "HARVEY/BRISTOL HOTELS"
    },
    {
      "mcc": "3735",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3736",
      "text": "COLORADO BELLE/EDGEWATER RESORT"
    },
    {
      "mcc": "3737",
      "text": "RIVIERA HOTEL AND CASINO"
    },
    {
      "mcc": "3738",
      "text": "TROPICANA RESORT AND CASINO"
    },
    {
      "mcc": "3739",
      "text": "WOODSIDE HOTELS AND RESORTS"
    },
    {
      "mcc": "3740",
      "text": "TOWNPLACE SUITES"
    },
    {
      "mcc": "3741",
      "text": "MILLENIUM BROADWAY HOTEL"
    },
    {
      "mcc": "3742",
      "text": "CLUB MED"
    },
    {
      "mcc": "3743",
      "text": "BILTMORE HOTEL AND SUITES"
    },
    {
      "mcc": "3744",
      "text": "CAREFREE RESORTS"
    },
    {
      "mcc": "3745",
      "text": "ST. REGIS HOTEL"
    },
    {
      "mcc": "3746",
      "text": "THE ELIOT HOTEL"
    },
    {
      "mcc": "3747",
      "text": "CLUBCORP/CLUB RESORTS"
    },
    {
      "mcc": "3748",
      "text": "WELESLEY INNS"
    },
    {
      "mcc": "3749",
      "text": "THE BEVERLY HILLS HOTEL"
    },
    {
      "mcc": "3750",
      "text": "CROWNE PLAZA HOTELS"
    },
    {
      "mcc": "3751",
      "text": "HOMEWOOD SUITES"
    },
    {
      "mcc": "3752",
      "text": "PEABODY HOTELS"
    },
    {
      "mcc": "3753",
      "text": "GREENBRIAH RESORTS"
    },
    {
      "mcc": "3754",
      "text": "AMELIA ISLAND PLANATION"
    },
    {
      "mcc": "3755",
      "text": "THE HOMESTEAD"
    },
    {
      "mcc": "3756",
      "text": "SOUTH SEAS RESORTS"
    },
    {
      "mcc": "3757",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3758",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3759",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3760",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3761",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3762",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3763",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3764",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3765",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3766",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3767",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3768",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3769",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3770",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3771",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3772",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3773",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3774",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3775",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3776",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3777",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3778",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3779",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3780",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3781",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3782",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3783",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3784",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3785",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3786",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3787",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3788",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3789",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3790",
      "text": "Hotels/Motels/Inns/Resorts"
    },
    {
      "mcc": "3816",
      "text": "Home2Suites"
    },
    {
      "mcc": "3835",
      "text": "* MASTERS ECONOMY INNS"
    },
    {
      "mcc": "4011",
      "text": "Railroads"
    },
    {
      "mcc": "4111",
      "text": "Local/Suburban Commuter Passenger Transportation – Railroads, Feries, Local Water Transportation."
    },
    {
      "mcc": "4112",
      "text": "Passenger Railways"
    },
    {
      "mcc": "4119",
      "text": "Ambulance Services"
    },
    {
      "mcc": "4121",
      "text": "Taxicabs and Limousines"
    },
    {
      "mcc": "4131",
      "text": "Bus Lines, Including Charters, Tour Buses"
    },
    {
      "mcc": "4214",
      "text": "Motor Freight Carriers, Moving and Storage Companies, Trucking – Local/Long Distance, Delivery Services – Local"
    },
    {
      "mcc": "4215",
      "text": "Courier Services – Air or Ground, Freight forwarders"
    },
    {
      "mcc": "4225",
      "text": "Public warehousing, Storage"
    },
    {
      "mcc": "4411",
      "text": "Cruise and Steamship Lines"
    },
    {
      "mcc": "4457",
      "text": "Boat Rentals and Leases"
    },
    {
      "mcc": "4468",
      "text": "Marinas, Marine Service, and Supplies"
    },
    {
      "mcc": "4511",
      "text": "Airlines, Air Carriers (not listed elsewhere)"
    },
    {
      "mcc": "4582",
      "text": "Airports, Airport Terminals, Flying Fields"
    },
    {
      "mcc": "4722",
      "text": "Travel Agencies and Tour Operations"
    },
    {
      "mcc": "4723",
      "text": "Package Tour Operators (For use in Germany only)"
    },
    {
      "mcc": "4784",
      "text": "Toll and Bridge Fees"
    },
    {
      "mcc": "4789",
      "text": "Transportation Services, Not elsewhere classified)"
    },
    {
      "mcc": "4812",
      "text": "Telecommunications Equipment including telephone sales"
    },
    {
      "mcc": "4814",
      "text": "Fax services, Telecommunication Services"
    },
    {
      "mcc": "4815",
      "text": "VisaPhone"
    },
    {
      "mcc": "4816",
      "text": "Computer Network Services"
    },
    {
      "mcc": "4821",
      "text": "Telegraph services"
    },
    {
      "mcc": "4829",
      "text": "Money Orders – Wire Transfer"
    },
    {
      "mcc": "4899",
      "text": "Cable and other pay television (previously Cable Services)"
    },
    {
      "mcc": "4900",
      "text": "Electric, Gas, Sanitary and Water Utilities"
    },
    {
      "mcc": "5013",
      "text": "Motor vehicle supplies and new parts"
    },
    {
      "mcc": "5021",
      "text": "Office and Commercial Furniture"
    },
    {
      "mcc": "5039",
      "text": "Construction Materials, Not Elsewhere Classified"
    },
    {
      "mcc": "5044",
      "text": "Office, Photographic, Photocopy, and Microfilm Equipment"
    },
    {
      "mcc": "5045",
      "text": "Computers, Computer Peripheral Equipment, Software"
    },
    {
      "mcc": "5046",
      "text": "Commercial Equipment, Not Elsewhere Classified"
    },
    {
      "mcc": "5047",
      "text": "Medical, Dental Ophthalmic, Hospital Equipment and Supplies"
    },
    {
      "mcc": "5051",
      "text": "Metal Service Centers and Offices"
    },
    {
      "mcc": "5065",
      "text": "Electrical Parts and Equipment"
    },
    {
      "mcc": "5072",
      "text": "Hardware Equipment and Supplies"
    },
    {
      "mcc": "5074",
      "text": "Plumbing and Heating Equipment and Supplies"
    },
    {
      "mcc": "5085",
      "text": "Industrial Supplies, Not Elsewhere Classified"
    },
    {
      "mcc": "5094",
      "text": "Precious Stones and Metals, Watches and Jewelry"
    },
    {
      "mcc": "5099",
      "text": "Durable Goods, Not Elsewhere Classified"
    },
    {
      "mcc": "5111",
      "text": "Stationery, Office Supplies, Printing, and Writing Paper"
    },
    {
      "mcc": "5122",
      "text": "Drugs, Drug Proprietors, and Druggist’s Sundries"
    },
    {
      "mcc": "5131",
      "text": "Piece Goods, Notions, and Other Dry Goods"
    },
    {
      "mcc": "5137",
      "text": "Men’s Women’s and Children’s Uniforms and Commercial Clothing"
    },
    {
      "mcc": "5139",
      "text": "Commercial Footwear"
    },
    {
      "mcc": "5169",
      "text": "Chemicals and Allied Products, Not Elsewhere Classified"
    },
    {
      "mcc": "5172",
      "text": "Petroleum and Petroleum Products"
    },
    {
      "mcc": "5192",
      "text": "Books, Periodicals, and Newspapers"
    },
    {
      "mcc": "5193",
      "text": "Florists’ Supplies, Nursery Stock and Flowers"
    },
    {
      "mcc": "5198",
      "text": "Paints, Varnishes, and Supplies"
    },
    {
      "mcc": "5199",
      "text": "Non-durable Goods, Not Elsewhere Classified"
    },
    {
      "mcc": "5200",
      "text": "Home Supply Warehouse Stores"
    },
    {
      "mcc": "5211",
      "text": "Lumber and Building Materials Stores"
    },
    {
      "mcc": "5231",
      "text": "Glass, Paint, and Wallpaper Stores"
    },
    {
      "mcc": "5251",
      "text": "Hardware Stores"
    },
    {
      "mcc": "5261",
      "text": "Nurseries – Lawn and Garden Supply Store"
    },
    {
      "mcc": "5271",
      "text": "Mobile Home Dealers"
    },
    {
      "mcc": "5300",
      "text": "Wholesale Clubs"
    },
    {
      "mcc": "5309",
      "text": "Duty Free Store"
    },
    {
      "mcc": "5310",
      "text": "Discount Stores"
    },
    {
      "mcc": "5311",
      "text": "Department Stores"
    },
    {
      "mcc": "5331",
      "text": "Variety Stores"
    },
    {
      "mcc": "5399",
      "text": "Misc. General Merchandise"
    },
    {
      "mcc": "5411",
      "text": "Grocery Stores, Supermarkets"
    },
    {
      "mcc": "5422",
      "text": "Meat Provisioners – Freezer and Locker"
    },
    {
      "mcc": "5441",
      "text": "Candy, Nut, and Confectionery Stores"
    },
    {
      "mcc": "5451",
      "text": "Dairy Products Stores"
    },
    {
      "mcc": "5462",
      "text": "Bakeries"
    },
    {
      "mcc": "5499",
      "text": "Misc. Food Stores – Convenience Stores and Specialty Markets"
    },
    {
      "mcc": "5511",
      "text": "Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing"
    },
    {
      "mcc": "5521",
      "text": "Automobile and Truck Dealers (Used Only)"
    },
    {
      "mcc": "5531",
      "text": "Automobile Supply Stores"
    },
    {
      "mcc": "5532",
      "text": "Automotive Tire Stores"
    },
    {
      "mcc": "5533",
      "text": "Automotive Parts, Accessories Stores"
    },
    {
      "mcc": "5541",
      "text": "Service Stations (with or without ancillary services)"
    },
    {
      "mcc": "5542",
      "text": "Automated Fuel Dispensers"
    },
    {
      "mcc": "5551",
      "text": "Boat Dealers"
    },
    {
      "mcc": "5561",
      "text": "Recreational and Utility Trailers, Camp Dealers"
    },
    {
      "mcc": "5571",
      "text": "Motorcycle Dealers"
    },
    {
      "mcc": "5592",
      "text": "Motor Home Dealers"
    },
    {
      "mcc": "5598",
      "text": "Snowmobile Dealers"
    },
    {
      "mcc": "5599",
      "text": "Miscellaneous Auto Dealers "
    },
    {
      "mcc": "5611",
      "text": "Men’s and Boy’s Clothing and Accessories Stores"
    },
    {
      "mcc": "5621",
      "text": "Women’s Ready-to-Wear Stores"
    },
    {
      "mcc": "5631",
      "text": "Women’s Accessory and Specialty Shops"
    },
    {
      "mcc": "5641",
      "text": "Children’s and Infant’s Wear Stores"
    },
    {
      "mcc": "5651",
      "text": "Family Clothing Stores"
    },
    {
      "mcc": "5655",
      "text": "Sports Apparel, Riding Apparel Stores"
    },
    {
      "mcc": "5661",
      "text": "Shoe Stores"
    },
    {
      "mcc": "5681",
      "text": "Furriers and Fur Shops"
    },
    {
      "mcc": "5691",
      "text": "Men’s and Women’s Clothing Stores"
    },
    {
      "mcc": "5697",
      "text": "Tailors, Seamstress, Mending, and Alterations"
    },
    {
      "mcc": "5698",
      "text": "Wig and Toupee Stores"
    },
    {
      "mcc": "5699",
      "text": "Miscellaneous Apparel and Accessory Shops"
    },
    {
      "mcc": "5712",
      "text": "Furniture, Home Furnishings, and Equipment Stores, ExceptAppliances"
    },
    {
      "mcc": "5713",
      "text": "Floor Covering Stores"
    },
    {
      "mcc": "5714",
      "text": "Drapery, Window Covering and Upholstery Stores"
    },
    {
      "mcc": "5718",
      "text": "Fireplace, Fireplace Screens, and Accessories Stores"
    },
    {
      "mcc": "5719",
      "text": "Miscellaneous Home Furnishing Specialty Stores"
    },
    {
      "mcc": "5722",
      "text": "Household Appliance Stores"
    },
    {
      "mcc": "5732",
      "text": "Electronic Sales"
    },
    {
      "mcc": "5733",
      "text": "Music Stores, Musical Instruments, Piano Sheet Music"
    },
    {
      "mcc": "5734",
      "text": "Computer Software Stores"
    },
    {
      "mcc": "5735",
      "text": "Record Shops"
    },
    {
      "mcc": "5811",
      "text": "Caterers"
    },
    {
      "mcc": "5812",
      "text": "Eating places and Restaurants"
    },
    {
      "mcc": "5813",
      "text": "Drinking Places (Alcoholic Beverages), Bars, Taverns, Cocktail lounges, Nightclubs and Discotheques"
    },
    {
      "mcc": "5814",
      "text": "Fast Food Restaurants"
    },
    {
      "mcc": "5815",
      "text": "Digital Goods: Media, Books, Movies, Music"
    },
    {
      "mcc": "5816",
      "text": "Digital Goods: Games"
    },
    {
      "mcc": "5817",
      "text": "Digital Goods: Applications (Excludes Games)"
    },
    {
      "mcc": "5818",
      "text": "Digital Goods: Large Digital Goods Merchant"
    },
    {
      "mcc": "5832",
      "text": "Antique Shops – Sales, Repairs, and Restoration Services"
    },
    {
      "mcc": "5912",
      "text": "Drug Stores and Pharmacies"
    },
    {
      "mcc": "5921",
      "text": "Package Stores – Beer, Wine, and Liquor"
    },
    {
      "mcc": "5931",
      "text": "Used Merchandise and Secondhand Stores"
    },
    {
      "mcc": "5932",
      "text": "Antique Shops"
    },
    {
      "mcc": "5933",
      "text": "Pawn Shops and Salvage Yards"
    },
    {
      "mcc": "5935",
      "text": "Wrecking and Salvage Yards"
    },
    {
      "mcc": "5937",
      "text": "Antique Reproductions"
    },
    {
      "mcc": "5940",
      "text": "Bicycle Shops – Sales and Service"
    },
    {
      "mcc": "5941",
      "text": "Sporting Goods Stores"
    },
    {
      "mcc": "5942",
      "text": "Book Stores"
    },
    {
      "mcc": "5943",
      "text": "Stationery Stores, Office and School Supply Stores"
    },
    {
      "mcc": "5944",
      "text": "Watch, Clock, Jewelry, and Silverware Stores"
    },
    {
      "mcc": "5945",
      "text": "Hobby, Toy, and Game Shops"
    },
    {
      "mcc": "5946",
      "text": "Camera and Photographic Supply Stores"
    },
    {
      "mcc": "5947",
      "text": "Card Shops, Gift, Novelty, and Souvenir Shops"
    },
    {
      "mcc": "5948",
      "text": "Leather Goods Stores"
    },
    {
      "mcc": "5949",
      "text": "Sewing, Needle, Fabric, and Price Goods Stores"
    },
    {
      "mcc": "5950",
      "text": "Glassware/Crystal Stores"
    },
    {
      "mcc": "5960",
      "text": "Direct Marketing- Insurance Service"
    },
    {
      "mcc": "5961",
      "text": "Mail Order Houses Including Catalog Order Stores, Book/Record Clubs (No longer permitted for U.S. original presentments)"
    },
    {
      "mcc": "5962",
      "text": "Direct Marketing – Travel Related Arrangements Services"
    },
    {
      "mcc": "5963",
      "text": "Door-to-Door Sales"
    },
    {
      "mcc": "5964",
      "text": "Direct Marketing – Catalog Merchant"
    },
    {
      "mcc": "5965",
      "text": "Direct Marketing – Catalog and Catalog and Retail Merchant"
    },
    {
      "mcc": "5966",
      "text": "Direct Marketing- Outbound Telemarketing Merchant"
    },
    {
      "mcc": "5967",
      "text": "Direct Marketing – Inbound Teleservices Merchant"
    },
    {
      "mcc": "5968",
      "text": "Direct Marketing – Continuity/Subscription Merchant"
    },
    {
      "mcc": "5969",
      "text": "Direct Marketing – Not Elsewhere Classified"
    },
    {
      "mcc": "5970",
      "text": "Artist’s Supply and Craft Shops"
    },
    {
      "mcc": "5971",
      "text": "Art Dealers and Galleries"
    },
    {
      "mcc": "5972",
      "text": "Stamp and Coin Stores – Philatelic and Numismatic Supplies"
    },
    {
      "mcc": "5973",
      "text": "Religious Goods Stores"
    },
    {
      "mcc": "5975",
      "text": "Hearing Aids – Sales, Service, and Supply Stores"
    },
    {
      "mcc": "5976",
      "text": "Orthopedic Goods Prosthetic Devices"
    },
    {
      "mcc": "5977",
      "text": "Cosmetic Stores"
    },
    {
      "mcc": "5978",
      "text": "Typewriter Stores – Sales, Rental, Service"
    },
    {
      "mcc": "5983",
      "text": "Fuel – Fuel Oil, Wood, Coal, Liquefied Petroleum"
    },
    {
      "mcc": "5992",
      "text": "Florists"
    },
    {
      "mcc": "5993",
      "text": "Cigar Stores and Stands"
    },
    {
      "mcc": "5994",
      "text": "News Dealers and Newsstands"
    },
    {
      "mcc": "5995",
      "text": "Pet Shops, Pet Foods, and Supplies Stores"
    },
    {
      "mcc": "5996",
      "text": "Swimming Pools – Sales, Service, and Supplies"
    },
    {
      "mcc": "5997",
      "text": "Electric Razor Stores – Sales and Service"
    },
    {
      "mcc": "5998",
      "text": "Tent and Awning Shops"
    },
    {
      "mcc": "5999",
      "text": "Miscellaneous and Specialty Retail Stores"
    },
    {
      "mcc": "6010",
      "text": "Financial Institutions – Manual Cash Disbursements"
    },
    {
      "mcc": "6011",
      "text": "Financial Institutions – Manual Cash Disbursements"
    },
    {
      "mcc": "6012",
      "text": "Financial Institutions – Merchandise and Services"
    },
    {
      "mcc": "6051",
      "text": "Non-Financial Institutions – Foreign Currency, Money Orders (not wire transfer) and Travelers Cheques"
    },
    {
      "mcc": "6211",
      "text": "Security Brokers/Dealers"
    },
    {
      "mcc": "6300",
      "text": "Insurance Sales, Underwriting, and Premiums"
    },
    {
      "mcc": "6381",
      "text": "Insurance Premiums, (no longer valid for first presentment work)"
    },
    {
      "mcc": "6399",
      "text": "Insurance, Not Elsewhere Classified (no longer valid forfirst presentment work)"
    },
    {
      "mcc": "6513",
      "text": "Real Estate Agents and Managers - Rentals"
    },
    {
      "mcc": "7011",
      "text": "Lodging – Hotels, Motels, Resorts, Central Reservation Services (not elsewhere classified)"
    },
    {
      "mcc": "7012",
      "text": "Timeshares"
    },
    {
      "mcc": "7032",
      "text": "Sporting and Recreational Camps"
    },
    {
      "mcc": "7033",
      "text": "Trailer Parks and Camp Grounds"
    },
    {
      "mcc": "7210",
      "text": "Laundry, Cleaning, and Garment Services"
    },
    {
      "mcc": "7211",
      "text": "Laundry – Family and Commercial"
    },
    {
      "mcc": "7216",
      "text": "Dry Cleaners"
    },
    {
      "mcc": "7217",
      "text": "Carpet and Upholstery Cleaning"
    },
    {
      "mcc": "7221",
      "text": "Photographic Studios"
    },
    {
      "mcc": "7230",
      "text": "Barber and Beauty Shops"
    },
    {
      "mcc": "7251",
      "text": "Shop Repair Shops and Shoe Shine Parlors, and Hat Cleaning Shops"
    },
    {
      "mcc": "7261",
      "text": "Funeral Service and Crematories"
    },
    {
      "mcc": "7273",
      "text": "Dating and Escort Services"
    },
    {
      "mcc": "7276",
      "text": "Tax Preparation Service"
    },
    {
      "mcc": "7277",
      "text": "Counseling Service – Debt, Marriage, Personal"
    },
    {
      "mcc": "7278",
      "text": "Buying/Shopping Services, Clubs"
    },
    {
      "mcc": "7296",
      "text": "Clothing Rental – Costumes, Formal Wear, Uniforms"
    },
    {
      "mcc": "7297",
      "text": "Massage Parlors"
    },
    {
      "mcc": "7298",
      "text": "Health and Beauty Shops"
    },
    {
      "mcc": "7299",
      "text": "Miscellaneous Personal Services (not elsewhere classifies)"
    },
    {
      "mcc": "7311",
      "text": "Advertising Services"
    },
    {
      "mcc": "7321",
      "text": "Consumer Credit Reporting Agencies"
    },
    {
      "mcc": "7332",
      "text": "Blueprinting and Photocopying Services"
    },
    {
      "mcc": "7333",
      "text": "Commercial Photography, Art and Graphics"
    },
    {
      "mcc": "7338",
      "text": "Quick Copy, Reproduction and Blueprinting Services"
    },
    {
      "mcc": "7339",
      "text": "Stenographic and Secretarial Support Services"
    },
    {
      "mcc": "7342",
      "text": "Exterminating and Disinfecting Services"
    },
    {
      "mcc": "7349",
      "text": "Cleaning and Maintenance, Janitorial Services"
    },
    {
      "mcc": "7361",
      "text": "Employment Agencies, Temporary Help Services"
    },
    {
      "mcc": "7372",
      "text": "Computer Programming, Integrated Systems Design and Data Processing Services"
    },
    {
      "mcc": "7375",
      "text": "Information Retrieval Services"
    },
    {
      "mcc": "7379",
      "text": "Computer Maintenance and Repair Services, Not Elsewhere Classified"
    },
    {
      "mcc": "7392",
      "text": "Management, Consulting, and Public Relations Services"
    },
    {
      "mcc": "7393",
      "text": "Protective and Security Services – Including Armored Carsand Guard Dogs"
    },
    {
      "mcc": "7394",
      "text": "Equipment Rental and Leasing Services, Tool Rental, Furniture Rental, and Appliance Rental"
    },
    {
      "mcc": "7395",
      "text": "Photofinishing Laboratories, Photo Developing"
    },
    {
      "mcc": "7399",
      "text": "Business Services, Not Elsewhere Classified"
    },
    {
      "mcc": "7511",
      "text": "Truck Stop"
    },
    {
      "mcc": "7512",
      "text": "Car Rental Companies (Not Listed Below)"
    },
    {
      "mcc": "7513",
      "text": "Truck and Utility Trailer Rentals"
    },
    {
      "mcc": "7519",
      "text": "Motor Home and Recreational Vehicle Rentals"
    },
    {
      "mcc": "7523",
      "text": "Automobile Parking Lots and Garages"
    },
    {
      "mcc": "7531",
      "text": "Automotive Body Repair Shops"
    },
    {
      "mcc": "7534",
      "text": "Tire Re-treading and Repair Shops"
    },
    {
      "mcc": "7535",
      "text": "Paint Shops – Automotive"
    },
    {
      "mcc": "7538",
      "text": "Automotive Service Shops"
    },
    {
      "mcc": "7542",
      "text": "Car Washes"
    },
    {
      "mcc": "7549",
      "text": "Towing Services"
    },
    {
      "mcc": "7622",
      "text": "Radio Repair Shops"
    },
    {
      "mcc": "7623",
      "text": "Air Conditioning and Refrigeration Repair Shops"
    },
    {
      "mcc": "7629",
      "text": "Electrical And Small Appliance Repair Shops"
    },
    {
      "mcc": "7631",
      "text": "Watch, Clock, and Jewelry Repair"
    },
    {
      "mcc": "7641",
      "text": "Furniture, Furniture Repair, and Furniture Refinishing"
    },
    {
      "mcc": "7692",
      "text": "Welding Repair"
    },
    {
      "mcc": "7699",
      "text": "Repair Shops and Related Services –Miscellaneous"
    },
    {
      "mcc": "7800",
      "text": "Government-Owned Lotteries"
    },
    {
      "mcc": "7801",
      "text": "Government-Licensed On-Line Casinos (On-Line Gambling)"
    },
    {
      "mcc": "7802",
      "text": "Government-Licensed Horse/Dog Racing"
    },
    {
      "mcc": "7829",
      "text": "Motion Pictures and Video Tape Production and Distribution"
    },
    {
      "mcc": "7832",
      "text": "Motion Picture Theaters"
    },
    {
      "mcc": "7841",
      "text": "Video Tape Rental Stores"
    },
    {
      "mcc": "7911",
      "text": "Dance Halls, Studios and Schools"
    },
    {
      "mcc": "7922",
      "text": "Theatrical Producers (Except Motion Pictures), Ticket Agencies"
    },
    {
      "mcc": "7929",
      "text": "Bands, Orchestras, and Miscellaneous Entertainers (Not Elsewhere Classified)"
    },
    {
      "mcc": "7932",
      "text": "Billiard and Pool Establishments"
    },
    {
      "mcc": "7933",
      "text": "Bowling Alleys"
    },
    {
      "mcc": "7941",
      "text": "Commercial Sports, Athletic Fields, Professional Sport Clubs, and Sport Promoters"
    },
    {
      "mcc": "7991",
      "text": "Tourist Attractions and Exhibits"
    },
    {
      "mcc": "7992",
      "text": "Golf Courses – Public"
    },
    {
      "mcc": "7993",
      "text": "Video Amusement Game Supplies"
    },
    {
      "mcc": "7994",
      "text": "Video Game Arcades/Establishments"
    },
    {
      "mcc": "7995",
      "text": "Betting (including Lottery Tickets, Casino Gaming Chips, Off-track Betting and Wagers at Race Tracks)"
    },
    {
      "mcc": "7996",
      "text": "Amusement Parks, Carnivals, Circuses, Fortune Tellers"
    },
    {
      "mcc": "7997",
      "text": "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses"
    },
    {
      "mcc": "7998",
      "text": "Aquariums, Sea-aquariums, Dolphinariums"
    },
    {
      "mcc": "7999",
      "text": "Recreation Services (Not Elsewhere Classified)"
    },
    {
      "mcc": "8011",
      "text": "Doctors and Physicians (Not Elsewhere Classified)"
    },
    {
      "mcc": "8021",
      "text": "Dentists and Orthodontists"
    },
    {
      "mcc": "8031",
      "text": "Osteopaths"
    },
    {
      "mcc": "8041",
      "text": "Chiropractors"
    },
    {
      "mcc": "8042",
      "text": "Optometrists and Ophthalmologists"
    },
    {
      "mcc": "8043",
      "text": "Opticians, Opticians Goods and Eyeglasses"
    },
    {
      "mcc": "8044",
      "text": "Opticians, Optical Goods, and Eyeglasses (no longer validfor first presentments)"
    },
    {
      "mcc": "8049",
      "text": "Podiatrists and Chiropodists"
    },
    {
      "mcc": "8050",
      "text": "Nursing and Personal Care Facilities"
    },
    {
      "mcc": "8062",
      "text": "Hospitals"
    },
    {
      "mcc": "8071",
      "text": "Medical and Dental Laboratories"
    },
    {
      "mcc": "8099",
      "text": "Medical Services and Health Practitioners (Not Elsewhere Classified)"
    },
    {
      "mcc": "8111",
      "text": "Legal Services and Attorneys"
    },
    {
      "mcc": "8211",
      "text": "Elementary and Secondary Schools"
    },
    {
      "mcc": "8220",
      "text": "Colleges, Junior Colleges, Universities, and ProfessionalSchools"
    },
    {
      "mcc": "8241",
      "text": "Correspondence Schools"
    },
    {
      "mcc": "8244",
      "text": "Business and Secretarial Schools"
    },
    {
      "mcc": "8249",
      "text": "Vocational Schools and Trade Schools"
    },
    {
      "mcc": "8299",
      "text": "Schools and Educational Services (Not Elsewhere Classified)"
    },
    {
      "mcc": "8351",
      "text": "Child Care Services"
    },
    {
      "mcc": "8398",
      "text": "Charitable and Social Service Organizations"
    },
    {
      "mcc": "8641",
      "text": "Civic, Fraternal, and Social Associations"
    },
    {
      "mcc": "8651",
      "text": "Political Organizations"
    },
    {
      "mcc": "8661",
      "text": "Religious Organizations"
    },
    {
      "mcc": "8675",
      "text": "Automobile Associations"
    },
    {
      "mcc": "8699",
      "text": "Membership Organizations (Not Elsewhere Classified)"
    },
    {
      "mcc": "8734",
      "text": "Testing Laboratories (non-medical)"
    },
    {
      "mcc": "8911",
      "text": "Architectural – Engineering and Surveying Services"
    },
    {
      "mcc": "8931",
      "text": "Accounting, Auditing, and Bookkeeping Services"
    },
    {
      "mcc": "8999",
      "text": "Professional Services (Not Elsewhere Defined)"
    },
    {
      "mcc": "9211",
      "text": "Court Costs, including Alimony and Child Support"
    },
    {
      "mcc": "9222",
      "text": "Fines"
    },
    {
      "mcc": "9223",
      "text": "Bail and Bond Payments"
    },
    {
      "mcc": "9311",
      "text": "Tax Payments"
    },
    {
      "mcc": "9399",
      "text": "Government Services (Not Elsewhere Classified)"
    },
    {
      "mcc": "9402",
      "text": "Postal Services – Government Only"
    },
    {
      "mcc": "9405",
      "text": "Intra – Government Transactions"
    },
    {
      "mcc": "9700",
      "text": "Automated Referral Service (For Visa Only)"
    },
    {
      "mcc": "9701",
      "text": "Visa Credential Service (For Visa Only)"
    },
    {
      "mcc": "9702",
      "text": "GCAS Emergency Services (For Visa Only)"
    },
    {
      "mcc": "9950",
      "text": "Intra – Company Purchases (For Visa Only)"
    }
  ]
