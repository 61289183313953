import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SocialAuthServiceConfig, GoogleLoginProvider, SocialLoginModule, GoogleSigninButtonModule, GoogleSigninButtonDirective } from '@abacritt/angularx-social-login';
import { SignInComponent } from './sign-in/sign-in.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatInputModule } from "@angular/material/input";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatIconModule } from "@angular/material/icon";
import { MatChipsModule, MAT_CHIPS_DEFAULT_OPTIONS } from '@angular/material/chips';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FullscreenOverlayContainer, OverlayContainer, OverlayModule } from '@angular/cdk/overlay'

import { SignOutComponent } from './sign-out/sign-out.component';
import { environment } from "../environments/environment";
import { ReportsComponent } from './reports/reports.component';
import { LinksComponent } from './links/links.component';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ExceptionInterceptor } from './interceptors/exception.interceptor';
import { ListMerchantsComponent } from './list-merchants/list-merchants.component';
import { CreateMerchantComponent } from './create-merchant/create-merchant.component';
import { ApikeyDialogComponent } from './apikey-dialog/apikey-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignOutComponent,
    ReportsComponent,
    LinksComponent,
    ListMerchantsComponent,
    CreateMerchantComponent,
    ApikeyDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SocialLoginModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCardModule,
    MatToolbarModule,
    MatGridListModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatAutocompleteModule,
    ClipboardModule,
    MatIconModule,
    MatSelectModule,
    FlexLayoutModule,
    MatChipsModule,
    MatSlideToggleModule,
    MatPaginatorModule,
    MatDialogModule,
    MatExpansionModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    OverlayModule,
    GoogleSigninButtonModule
  ],
  providers: [{
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: true,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            environment.googleProviderId
          ),
        },
      ],
    } as SocialAuthServiceConfig,
  },
  GoogleSigninButtonDirective,
  {
    provide: MAT_CHIPS_DEFAULT_OPTIONS,
    useValue: {
      separatorKeyCodes: [ENTER, COMMA, SPACE, TAB],
    }
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ExceptionInterceptor,
    multi: true
  },
  {
    provide: OverlayContainer, 
    useClass: FullscreenOverlayContainer
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
