import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Paginator } from '../models/paginator';
import { SearchResponse } from '../models/search';

@Injectable({
  providedIn: 'root'
})
export class SearchTermService {
  term = "";
  constructor(private http: HttpClient) { }

  saveTerm(term: string){
    this.term = term
  }

  getTerm(): string{
    return this.term
  }

  /** SEARCH merchants from the server */
  search(paginator: Paginator): Observable<SearchResponse> {
    return this.http.get<SearchResponse>(environment.baseUrl + `/api/merchants?filter=` + encodeURIComponent(this.term) + `&page=${paginator.currentPage}&limit=${paginator.pageSize}&sort=${paginator.sort}&order=${paginator.order}`);
  }
}
