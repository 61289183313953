import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Gateway } from '../models/gateway';
import { ApiKey } from '../models/api-key';
import { Role } from '../models/role';
import { User } from '../models/user';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-apikey-dialog',
  templateUrl: './apikey-dialog.component.html',
  styleUrls: ['./apikey-dialog.component.sass']
})
export class ApikeyDialogComponent implements OnInit {
  user: User;
  apiKey: ApiKey;

  constructor(
      private dialogRef: MatDialogRef<ApikeyDialogComponent>,
      private userService: UserService,
      @Inject(MAT_DIALOG_DATA) apiKey:ApiKey,
  ) {
    this.user = this.userService?.currentUser;
    
    if (!apiKey) {
      this.apiKey = new ApiKey();
      this.apiKey.isActive = true;
    } else {
      this.apiKey = Object.assign({}, apiKey);
    }
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(this.apiKey);
  }

  close() {
    this.dialogRef.close();
  }

  get isAdmin() {
    return this.user?.Role === Role.Admin;
  }

}
