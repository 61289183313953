import {Component, OnInit} from '@angular/core';
import {GoogleLoginProvider, SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {from} from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
  public socialUser: SocialUser;

  constructor(private socialAuthService: SocialAuthService, private router: Router, private userService: UserService) {
  }

  ngOnInit() {
    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then(() => {
      // Any post-refresh actions if needed
    });
    
    this.socialAuthService.authState.subscribe((user) => {
      this.socialUser = user;

      if (user != null) {
        return from(this.userService.getUserRole())
          .subscribe(user => {
            // store user details in local storage
            localStorage.setItem('user', JSON.stringify(user));

            const url = this.userService.redirectUrl ?? "/"
            this.router.navigate([url != '/sign-in' ? url : "/"]);
          });
      }
    });
  }

  async refreshUserToken() {
    await this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID);
  }
}
