export class Paginator {
    currentPage: number;
    pageSize: number;
    pageSizeOptions: number[];
    total: number;
    sort: string;
    order: string;

    constructor(currentPage: number = 0, pageSize: number = 15, pageSizeOptions: number[] = [15, 25, 50, 100], 
      total: number = 0, sort: string = "", order: string = "") {
      this.currentPage = currentPage;
      this.pageSize = pageSize;
      this.pageSizeOptions = pageSizeOptions;
      this.total = total;
      this.sort = sort;
      this.order = order;
    }
}