import { ApiKey } from "./api-key";
import { ApplePayPaymentProcessingCertificate } from "./apple-pay-payment-proc-cert";

export class Merchant {
  id: string;
  mid: string;
  acquirerMid: string;
  amexMid: string;
  apiKeys: Array<ApiKey>;
  countryCode: string;
  description: string;
  domainNames: Array<string>;
  encryptedGatewayPassword: string;
  isActive: boolean;
  isApplePayEnabled: boolean;
  isGooglePayEnabled: boolean;
  isMotoEnabled: boolean;
  isMarsPayfacEnabled: boolean;
  isMarsVisaEnabled: boolean;
  isMerchantInitiatedTransactionsEnabled: boolean;
  isMarsMastercardEnabled: boolean;
  gatewayUsername: string;
  gatewayName: string;
  gatewayPassword: string;
  locatorId: string;
  mcc: string;
  name: string;
  partnerName: string;
  applePayPaymentProcessingCertificates: Array<ApplePayPaymentProcessingCertificate>
  remoteId: string;
  url: string;
  createdBy: string;
  createdDateUtc: number;
  lastModifiedBy: string;
  lastModifiedDateUtc: number;
  emailAddress: string;
  phoneNumber: string;
  address: Address;
}

export class Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  postcode: string;
  countryCode: string;
}
