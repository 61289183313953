<h2 mat-dialog-title>API Key</h2>

<form #apiKeyForm="ngForm" fxLayout="column" fxFill fxLayoutGap fxLayoutAlign="center center" *ngIf="apiKey">
  <div fxFill>
    <mat-slide-toggle fxFill name="isActive" [checked]="apiKey.isActive"
      [(ngModel)]="apiKey.isActive" [disabled]="!isAdmin">
      Active
      <mat-hint> - unchecking will disable the API key</mat-hint>
    </mat-slide-toggle>
  </div>
  
  <mat-dialog-content fxFill>
    <mat-form-field fxFill *ngIf="apiKey.key">
      <input matInput disabled placeholder="Key" name="apiKey" [(ngModel)]="apiKey.key">
    </mat-form-field>

    <mat-form-field fxFill *ngIf="apiKey.jwtToken">
      <button class="copy-button" mat-icon-button color="accent" [cdkCopyToClipboard]="apiKey.jwtToken" matTooltip="Copy JWT Token">
        <mat-icon aria-label="Copy JWT Token">content_copy</mat-icon>
      </button>
      <textarea matInput disabled rows="8" placeholder="JWT Token" name="jwtToken" [(ngModel)]="apiKey.jwtToken"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="end">
      <button mat-raised-button (click)="close()">Close</button>
      <button color="primary" mat-raised-button (click)="save()" *ngIf="isAdmin" [disabled]="!apiKeyForm.valid || !apiKeyForm.dirty">Save</button>
  </mat-dialog-actions>
</form>