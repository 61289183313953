<!--The content below is only a placeholder and can be replaced.-->

<mat-toolbar color="{{color}}">
  <mat-toolbar-row>
    <span>Remote AG Admin Tool - {{env}}</span>
    <app-links></app-links>
    <span class="spacer"></span>

    <app-sign-out></app-sign-out>
  </mat-toolbar-row>
</mat-toolbar>


<div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center">
  <router-outlet></router-outlet>
</div>
