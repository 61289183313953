import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiKey } from '../models/api-key';
import { Address, Merchant } from '../models/merchant';
import { Role } from '../models/role';
import { User } from '../models/user';
import { MerchantService } from '../services/merchant.service';
import { UserService } from '../services/user.service';
import { Mode } from '../models/mode';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ApikeyDialogComponent } from '../apikey-dialog/apikey-dialog.component';
import { MatChipInputEvent } from '@angular/material/chips';
import { MccCode, MccCodes } from '../models/mcc';
import { Gateway } from '../models/gateway';
import { map, startWith } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplePayPaymentProcessingCertificate } from '../models/apple-pay-payment-proc-cert';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-create-merchant',
  templateUrl: './create-merchant.component.html',
  styleUrls: ['./create-merchant.component.sass']
})
export class CreateMerchantComponent implements OnInit {
  gateways = Object.values(Gateway);
  passwordMask = "************";

  mode: string;
  user: User;
  merchant: Merchant;

  mccCtrl = new UntypedFormControl();
  mccOptions: MccCode[] = MccCodes;
  filteredMccOptions: Observable<MccCode[]>;

  partnerCtrl = new UntypedFormControl();
  partnerOptions: string[] = [];
  filteredPartnerOptions: Observable<string[]>;

  displayedColumns: string[] = ['key', 'isActive', 'createdDateUtc', 'actions'];
  dataSource = new MatTableDataSource<ApiKey>();

  applePayProcessingCertsDisplayedColumns: string[] = ['merchantId', 'creationDateTime', 'expirationDateTime'];
  applePayProcessingCertsDataSource = new MatTableDataSource<ApplePayPaymentProcessingCertificate>();

  apiKeysCtrl = new UntypedFormControl();
  domainNamesCtrl = new UntypedFormControl();

  registeredDomainNames: string[] = [];
  isProcessing = false;

  spinnerOverlayRef: OverlayRef;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatAccordion, {static: true}) accordion: MatAccordion;

  constructor(private merchantService: MerchantService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private overlay: Overlay) {
    this.user = this.userService?.currentUser;

    if(!this.spinnerOverlayRef) {
      this.spinnerOverlayRef = this.overlay.create({
        hasBackdrop: true,
        backdropClass: 'dark-backdrop',
        positionStrategy: this.overlay.position()
            .global()
            .centerHorizontally()
            .centerVertically()
        });
    }
  }

  ngOnInit() {
    this.dataSource.sort = this.sort;

    let mid = this.route.snapshot.paramMap.get('mid');
    if (mid) {
      this.mode = Mode.Update;

      if (this.isViewer) {
        this.mode = Mode.View;
        this.domainNamesCtrl.disable();
      }

      if (!this.isAdmin) {
        this.partnerCtrl.disable();
        this.mccCtrl.disable();
      }

      this.merchantService.getRegisteredDomainNamesByMID(mid)
        .subscribe({
          next: (v) => {
            this.registeredDomainNames = v;
            this.domainNamesCtrl.markAsPristine();
          },
          error: (e) => {
            console.error('error trying to fetch registered domain names.', e);
            this.merchantService.getByMID(mid)
            .subscribe(merchant => {
              this.populateMerchant(merchant);
            })
          },
          complete: () => {
              this.merchantService.getByMID(mid)
            .subscribe(merchant => {
              this.populateMerchant(merchant);
            })
          }
        });
    } else {
      this.mode = Mode.Create;

      this.merchant = history?.state?.merchant ?? new Merchant();
      this.merchant.id = undefined;
      this.merchant.mid = undefined;
      this.merchant.apiKeys = [];
      this.merchant.domainNames = [];
      this.merchant.gatewayName = Gateway.PayVector;
      this.merchant.gatewayUsername = undefined;
      this.merchant.gatewayPassword = undefined;
      this.merchant.encryptedGatewayPassword = undefined;
      this.merchant.url = undefined;
      this.merchant.isActive = true;
      this.merchant.applePayPaymentProcessingCertificates = [];
      this.merchant.createdBy = undefined;
      this.merchant.createdDateUtc = undefined;
      this.merchant.lastModifiedBy = undefined;
      this.merchant.lastModifiedDateUtc = undefined;

      if (!this.merchant.address) {
        this.merchant.address = new Address()
      }

      var apiKey = new ApiKey();
      apiKey.isActive = true;
      this.merchant.apiKeys.push(apiKey);
    }

    this.merchantService.list()
      .subscribe(merchants => {
        this.partnerOptions = merchants
          .filter(merchant => merchant.partnerName)
          .map(merchant => merchant.partnerName)
          .reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], []);
      })

    this.filteredMccOptions = this.mccCtrl.valueChanges
      .pipe(startWith(''), map(value => this.filterMccCode(value)));

    this.filteredPartnerOptions = this.partnerCtrl.valueChanges
      .pipe(startWith(''), map(value => this.filterPartner(value)));
  }

  submit() {
    this.clearPasswordMask();
    this.merchant.address.countryCode = this.merchant.countryCode
    this.merchantService.create(this.merchant)
      .subscribe(merchant => {
        if (merchant) {
          this.router.navigate(['merchants', merchant.mid])
          this.snackBar.open('merchant has been created successfully', "close", {duration: 5000});
        }
      });
  }

  update() {
    this.isProcessing = true;
    this.spinnerOverlayRef.attach(new ComponentPortal(MatProgressSpinner));

    this.clearPasswordMask();
    this.merchant.address.countryCode = this.merchant.countryCode
    this.merchantService.update(this.merchant)
      .subscribe(merchant => {
        if (merchant) {
          setTimeout(() => 
          {
            this.merchantService.getRegisteredDomainNamesByMID(merchant.mid)
            .subscribe({
              next: (v) => {
                this.registeredDomainNames = v;
                this.domainNamesCtrl.markAsPristine();
              },
              error: (e) => {
                console.error('error trying to fetch registered domain names.', e);
                this.isProcessing = false;
                this.spinnerOverlayRef.detach();
              },
              complete: () => {
                this.isProcessing = false;
                this.spinnerOverlayRef.detach();
                this.populateMerchant(merchant);
                this.snackBar.open('merchant has been updated successfully', "close", {duration: 5000});
              }
            });
          },
          1000);
        }
      });
  }

  updateAddDomainNames() {
    this.isProcessing = true;
    this.spinnerOverlayRef.attach(new ComponentPortal(MatProgressSpinner));

    this.merchantService.addDomainNames(this.merchant.mid, this.merchant.domainNames)
      .subscribe(() => {
        setTimeout(() => 
        {
          this.merchantService.getRegisteredDomainNamesByMID(this.merchant.mid)
          .subscribe({
            next: (v) => {
              this.registeredDomainNames = v;
              this.domainNamesCtrl.markAsPristine();
            },
            error: (e) => {
              console.error('error trying to fetch registered domain names.', e);
              this.isProcessing = false;
              this.spinnerOverlayRef.detach();
            },
            complete: () => {
              this.isProcessing = false;
              this.spinnerOverlayRef.detach();
              this.snackBar.open('merchant has been updated successfully', "close", {duration: 5000});
            }
          });
        },
        1000);
      });
  }

  addDomainName(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.merchant.domainNames.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();
    this.domainNamesCtrl.setValue(null);
  }

  removeDomainName(domainName: string): void {
    const index = this.merchant.domainNames.indexOf(domainName);

    if (index >= 0) {
      this.merchant.domainNames.splice(index, 1);
      this.domainNamesCtrl.markAsDirty();
    }
  }

  isDomainRegistered(domainName: string): boolean {
    return this.registeredDomainNames?.find(d => d == domainName) ? true : false;
  }

  generateApiKey() {
    var apiKey = new ApiKey();
    apiKey.isActive = true;

    this.merchant.apiKeys.push(apiKey);
    this.dataSource.data = this.merchant.apiKeys;
    this.apiKeysCtrl.markAsDirty();
  }

  editApiKey(index: number, apiKey:ApiKey) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = apiKey;
    dialogConfig.disableClose = true;
    dialogConfig.minWidth = '50%';

    const dialogRef = this.dialog.open(ApikeyDialogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(val => {
      if (val) {
        this.merchant.apiKeys[index] = val;
        this.dataSource.data = this.merchant.apiKeys;
        this.apiKeysCtrl.markAsDirty();
      }
    });
  }

  deleteApiKey(index: number, apiKey:ApiKey) {
    // do not delete stored keys
    if (!apiKey.key) {
      this.merchant.apiKeys.splice(index, 1);
      this.dataSource.data = this.merchant.apiKeys;
    }
  }

  keyPress($event: KeyboardEvent, auto: MatAutocomplete): boolean {
    if ($event.key == "Enter" && auto.isOpen) {
      $event.stopPropagation();
      return false;
    }

    return true;
  }

  onMccSelectionChange(event){
    this.mccCtrl.markAsDirty();
  }

  onPartnerSelectionChange(event){
    this.partnerCtrl.markAsDirty();
  }

  clearPasswordMask() {
    if (this.merchant?.gatewayPassword == this.passwordMask) {
      this.merchant.gatewayPassword = "";
    }
  }

  resetPassword() {
    if (this.merchant?.gatewayPassword == this.passwordMask) {
      this.merchant.gatewayPassword = "";
      this.merchant.encryptedGatewayPassword = "";
    }
  }

  private populateMerchant(merchant: Merchant) {
    this.merchant = merchant;

    if (!this.merchant.domainNames) {
      this.merchant.domainNames = [];
    }

    if (!this.merchant.apiKeys) {
      this.merchant.apiKeys = [];
    }

    if (this.merchant.encryptedGatewayPassword && this.merchant.encryptedGatewayPassword != "") {
      this.merchant.gatewayPassword = this.passwordMask;
    }

    if (!this.merchant.applePayPaymentProcessingCertificates) {
      this.merchant.applePayPaymentProcessingCertificates = [];
    }

    this.dataSource.data = this.merchant.apiKeys;
    this.applePayProcessingCertsDataSource.data = this.merchant.applePayPaymentProcessingCertificates;
  }

  private filterPartner(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.partnerOptions.filter(option => option.toLowerCase().includes(filterValue));
  }

  private filterMccCode(value: string): MccCode[]{
    const lower = value.toLowerCase();
    return this.mccOptions.filter(code => code.mcc.toLowerCase().includes(lower) || code.text.toLowerCase().includes(lower));
  }

  get isCybersource() {
    return this.merchant?.gatewayName == Gateway.Cybersource;
  }

  get isDojo() {
    return this.merchant?.gatewayName == Gateway.SmartVista;
  }

  get isAdmin() {
    return this.user?.Role === Role.Admin;
  }

  get isApplePayDomainAdder() {
    return this.user?.Role === Role.ApplePayDomainAdder;
  }

  get isViewer() {
    return this.user?.Role === Role.Viewer;
  }

  get isCreateMode() {
    return this.mode == Mode.Create;
  }

  get isUpdateMode() {
    return this.mode == Mode.Update;
  }

}
