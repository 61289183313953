import { Component, OnInit } from '@angular/core';
import {SocialAuthService, SocialUser} from '@abacritt/angularx-social-login';
import {Router} from '@angular/router';
import {UserService} from '../services/user.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: ['./sign-out.component.scss']
})
export class SignOutComponent implements OnInit {

  public socialUser: SocialUser;
  constructor(private authService: SocialAuthService, private router: Router, private userService: UserService) { }

  ngOnInit() {
    this.userService.userObservable.subscribe(u => this.socialUser = u);
  }

  signOut(): void {
    this.authService.signOut();
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    this.router.navigate(['/sign-in']);
  }

}
