import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { SocialUser } from '@abacritt/angularx-social-login';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private userService: UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.userService.getUser()).pipe(mergeMap((user: SocialUser) => {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.idToken}`
        }
      });

      return next.handle(request);
    }));
  }
}
