import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Merchant } from '../models/merchant';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MerchantService {
  constructor(private http: HttpClient) { }

  /** LIST merchants from the server */
  list(): Observable<Merchant[]> {
    return this.http.get<Merchant[]>(environment.baseUrl + `/api/merchants`);
  }

  /** GET merchant from the server */
  getByMID(mid: string): Observable<Merchant> {
    return this.http.get<Merchant>(environment.baseUrl + `/api/merchants/${mid}`);
  }

  /** CREATE merchant from the server */
  create(merchant: Merchant): Observable<Merchant> {
    return this.http.post<Merchant>(environment.baseUrl + `/api/merchants`, merchant);
  }

  /** UPDATE merchant from the server */
  update(merchant: Merchant): Observable<Merchant> {
    return this.http.put<Merchant>(environment.baseUrl + `/api/merchants/${merchant.id}`, merchant);
  }

  /** PATCH add domain names */
  addDomainNames(mid: string, domainNames:string[]): Observable<{}> {
    return this.http.patch(environment.baseUrl + `/api/merchants/${mid}/domain-names/add`, domainNames);
  }

  /** GET merchant registered domains from the server */
  getRegisteredDomainNamesByMID(mid: string): Observable<string[]> {
    return this.http.get<string[]>(environment.baseUrl + `/api/merchants/${mid}/domain-names`);
  }
}
